import { lazy } from "react";
import { RoutesNames } from "./enum";

const ConsultationPage = lazy(() => import("../pages/consultations"));
const SchedulePage = lazy(() => import("../pages/consultations/Schedule"));
const DoctorVerifyPage = lazy(() => import("../pages/verify"));
const DoctorDetailsPage = lazy(() => import("../pages/verify/DoctorDetails"));
const SettingsPage = lazy(() => import("../pages/settings"));
const ChatPage = lazy(() => import("../pages/chat"));
const ChatDetailsPage = lazy(() => import("../pages/chat/ChatDetails"));
const MedicalHistoryPage = lazy(() => import("../pages/history"));
const MedicalHistoryPatientsPage = lazy(() => import("../pages/history/Patients"));
const MedicalHistoryConsultationsPage = lazy(() => import("../pages/history/Consultations"));
const PrescriptionPage = lazy(() => import("../pages/prescription"));
const AppointmentsPage = lazy(() => import("../pages/appointments"));
const CommissionPage = lazy(() => import("../pages/finance/Commission"));
const DoctorReportPage = lazy(() => import("../pages/finance/DoctorReport"));
const PatientReportPage = lazy(() => import("../pages/finance/PatientReport"));
const PromoPage = lazy(() => import("../pages/finance/Promo"));
const PatientPrescriptionPage = lazy(() => import("../pages/prescription/Patients"));
const PatientPrescriptionsPage = lazy(() => import("../pages/prescription/PatientPrescriptions"));
const ConsultationsPage = lazy(() => import("../pages/appointments/Consultations"));
const PatientDetailsPage = lazy(() => import("../pages/appointments/PatientDetails"));
const PromoDetailsPage = lazy(() => import("../pages/finance/PromoDetails"));

export const routes = [
    {
        id: 1,
        name: "consultation",
        path: RoutesNames.CONSULTATION,
        element: <ConsultationPage />
    },
    {
        id: 2,
        name: "appointments",
        path: RoutesNames.APPOINTMENTS,
        element: <AppointmentsPage />
    },
    {
        id: 3,
        name: "chat",
        path: RoutesNames.CHAT,
        element: <ChatPage />
    },
    {
        id: 4,
        name: "commission",
        path: RoutesNames.COMMISSION,
        element: <CommissionPage />
    },
    {
        id: 5,
        name: "doctor-report",
        path: RoutesNames.DOCTOR_REPORT,
        element: <DoctorReportPage />
    },
    {
        id: 6,
        name: "medical-history",
        path: RoutesNames.MEDICAL_HISTORY,
        element: <MedicalHistoryPage />
    },
    {
        id: 7,
        name: "patient-report",
        path: RoutesNames.PATIENT_REPORT,
        element: <PatientReportPage />
    },
    {
        id: 8,
        name: "promo-code",
        path: RoutesNames.PROMO_CODE,
        element: <PromoPage />
    },
    {
        id: 9,
        name: "recipes",
        path: RoutesNames.RECIPES,
        element: <PrescriptionPage />
    },
    {
        id: 10,
        name: "settings",
        path: RoutesNames.SETTINGS,
        element: <SettingsPage />
    },
    {
        id: 11,
        name: "doctors-verify",
        path: RoutesNames.VERIFY,
        element: <DoctorVerifyPage />
    },
    {
        id: 12,
        name: "doctor_schedule",
        path: `${RoutesNames.CONSULTATION}/:doctorId`,
        element: <SchedulePage />
    },
    {
        id: 13,
        name: "doctors-details",
        path: `${RoutesNames.VERIFY}/:doctorId`,
        element: <DoctorDetailsPage />
    },
    {
        id: 14,
        name: "chat-details",
        path: `${RoutesNames.CHAT}/:doctorId`,
        element: <ChatDetailsPage />
    },
    {
        id: 15,
        name: "history-patients",
        path: `${RoutesNames.MEDICAL_HISTORY}/:doctorId`,
        element: <MedicalHistoryPatientsPage />
    },
    {
        id: 16,
        name: "history-doctor-patients",
        path: `${RoutesNames.MEDICAL_HISTORY}/:doctorId/:patientId`,
        element: <MedicalHistoryConsultationsPage />
    },
    {
        id: 17,
        name: "prescription-patients",
        path: `${RoutesNames.RECIPES}/:doctorId`,
        element: <PatientPrescriptionPage />
    },
    {
        id: 18,
        name: "prescription-doctor-patients",
        path: `${RoutesNames.RECIPES}/:doctorId/:patientId`,
        element: <PatientPrescriptionsPage />
    },
    {
        id: 19,
        name: "doctor-appointments",
        path: `${RoutesNames.APPOINTMENTS}/:doctorId`,
        element: <ConsultationsPage />
    },
    {
        id: 20,
        name: "doctor-patient-appointments",
        path: `${RoutesNames.APPOINTMENTS}/:doctorId/:patientId`,
        element: <PatientDetailsPage />
    },
    {
        id: 21,
        name: "doctor-promo",
        path: `${RoutesNames.PROMO_CODE}/:doctorId`,
        element: <PromoDetailsPage />
    }
];
