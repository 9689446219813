import {
    CalendarOutlined,
    FileSearchOutlined,
    FileTextOutlined,
    LaptopOutlined,
    MessageOutlined,
    UserOutlined,
    WalletOutlined,
    SettingOutlined
} from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserRole } from "../../models/authTypes";

interface Props {
    selectedLink: string[];
}

export const NavbarLink: FC<Props> = ({ selectedLink }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const navLinks: any[] = [
        {
            key: "doctors",
            icon: <LaptopOutlined />,
            label: t("Active doctors")
        },
        {
            key: "appointments",
            icon: <CalendarOutlined />,
            label: t("Records")
        },
        {
            key: "prescription",
            icon: <FileTextOutlined />,
            label: t("Recipes")
        },
        {
            key: "medical-history",
            icon: <FileSearchOutlined />,
            label: t("Medical history")
        },
        {
            key: "finance",
            icon: <WalletOutlined />,
            label: t("Finance"),
            children: [
                {
                    key: "commission",
                    label: t("Set commission")
                },
                {
                    key: "promo-code",
                    label: t("Promo code")
                },
                {
                    key: "patient-report",
                    label: t("Report on patients")
                },
                {
                    key: "doctor-report",
                    label: t("Report on doctors")
                }
            ]
        },
        {
            key: "chat",
            icon: <MessageOutlined />,
            label: <ChatMenuItem>{t("Chat")}</ChatMenuItem>
        },
        {
            key: "doctor-verify",
            icon: <UserOutlined />,
            label: t("Doctor Verification")
        },
        {
            key: "settings",
            icon: <SettingOutlined />,
            label: t("Settings")
        }
    ];

    const onSelectMenuItem: MenuProps["onClick"] = (e) => {
        navigate(`/${UserRole.ADMIN}/${e.key}`);
    };

    return (
        <Menu
            mode="inline"
            selectedKeys={selectedLink}
            onSelect={onSelectMenuItem}
            items={navLinks}
        />
    );
};

const ChatMenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;
