import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { uid } from "uid";
import { BreadcrumbContext } from "../contexts/BreadcrumbContext";

export const useBreadcrumb = () => {
    const [pageParams, setPageParams] = useContext(BreadcrumbContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const getPathName = (path: string) => {
        switch (path) {
            case "doctor":
            case "patient":
            case "admin":
                return t("Home");
            case "appointments":
                return t("Records");
            case "prescription":
                return t("Recipes");
            case "patients":
                return t("Patients");
            case "finance":
                return t("Finance");
            case "chat":
                return t("Chat");
            case "profile":
                return t("Profile");
            case "doctors":
                return t("Active doctors");
            case "medical-history":
                return t("Medical history");
            case "video":
                return t("Video chat");
            case "details":
                return t("Recipe details");
            case "order":
                return t("Order a recipe");
            case "service_rules":
                return "FAQ";
            case "success":
                return t("Receipt no", { number: uid() });
            case "commission":
                return t("Set commission");
            case "promo-code":
                return t("Promo code");
            case "patient-report":
                return t("Report on patients");
            case "doctor-report":
                return t("Report on doctors");
            case "doctor-verify":
                return t("Doctor Verification");
            case "settings":
                return t("Settings");
            default:
                return pageParams.routeName;
        }
    };

    const breadcrumbs = pathname
        .split("/")
        .filter((el) => el)
        .map((path) => ({ path, name: getPathName(path) }));

    const navigateTo = (index: number) => () => {
        const link: string = breadcrumbs
            .slice(0, index + 1)
            .map((el) => `/${el.path}`)
            .join("");

        navigate(link);
    };

    return {
        breadcrumbs,
        navigateTo,
        setPageParams,
        pageTitle: pageParams.pageTitle
    };
};
