import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RcFile } from "antd/es/upload/interface";
import dayjs from "dayjs";
import type {
    DoctorDetailResponse,
    DoctorWorkTimeResponse,
    IBreakTimeResponse,
    IConsultationState,
    IDoctorCard,
    IPagination,
    IPatientSchedule,
    ISuccessConsultation,
    IVacationResponse,
    IWorkTimeResponse
} from "../../models/consultationsTypes";
import { ConsultationStatus } from "../../models/recordsTypes";
import { UserStatus } from "../../models/userTypes";

const initialState: IConsultationState = {
    total: 0,
    doctors: [],
    newConsultation: {
        patient_id: "",
        doctor_id: "",
        date: "",
        start: 0,
        end: 0,
        price: 0,
        comment: "",
        season: null,
        promo: null,
        id: "",
        isFree: false,
        isSeason: false,
        isPromo: false,
        type: "video chat",
        status: ConsultationStatus.FUTURE,
        createdAt: "",
        updatedAt: ""
    },
    doctorSchedule: {
        workTime: [],
        vacations: [],
        additionalBreaks: []
    },
    patientSchedule: {
        doctor_id: "",
        date: dayjs().toISOString(),
        start: null,
        end: null,
        price: 0,
        isFree: false,
        type: "video chat",
        comment: "",
        files: []
    },
    pagination: {
        skip: 0,
        take: 10
    },
    doctorDetail: {
        about: "",
        docs: [],
        bio: "",
        education: "",
        firstName: "",
        prices: {
            consultation_price: 0,
            recipe_price: 0,
            season_price: 0,
            consultation_length: 40,
            rest_length: 10
        },
        id: "",
        languages: [],
        lastName: "",
        publicUrl: "",
        specialization: [],
        surName: "",
        work_bio: "",
        location_country: "",
        location_city: "",
        sex: "",
        activeStatus: UserStatus.ON_REVIEW,
        birthdate: "",
        city: "",
        email: "",
        phone: "",
        country: ""
    },
    workTimes: [],
    isLoading: false,
    isSavingSchedule: false,
    isSavingVacation: false,
    isSaveBreak: false,
    requestingTimes: false,
    error: ""
};

export const consultationSlice = createSlice({
    name: "consultation",
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.isSavingSchedule = false;
            state.isSavingVacation = false;
            state.isSaveBreak = false;
            state.requestingTimes = false;
            state.error = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setRequesting(state, action: PayloadAction<boolean>) {
            state.requestingTimes = action.payload;
        },
        setSavingVacation(state, action: PayloadAction<boolean>) {
            state.isSavingVacation = action.payload;
        },
        setSavingBreak(state, action: PayloadAction<boolean>) {
            state.isSaveBreak = action.payload;
        },
        setDoctorList(state, action: PayloadAction<Array<IDoctorCard>>) {
            state.isLoading = false;
            state.error = "";
            state.doctors = action.payload;
        },
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setPagination(state, action: PayloadAction<IPagination>) {
            state.pagination = action.payload;
        },
        setDoctorDetails(state, action: PayloadAction<DoctorDetailResponse>) {
            state.isLoading = false;
            state.error = "";
            state.doctorDetail = action.payload;
        },
        setPatientSchedule(state, action: PayloadAction<IPatientSchedule>) {
            state.patientSchedule = action.payload;
        },
        setPatientFiles(state, action: PayloadAction<RcFile[]>) {
            state.patientSchedule.files = action.payload;
        },
        setSavingWorkTime(state, action: PayloadAction<boolean>) {
            state.isSavingSchedule = action.payload;
            state.error = "";
        },
        setDoctorSchedule(state, action: PayloadAction<DoctorWorkTimeResponse>) {
            state.isLoading = false;
            state.error = "";
            state.doctorSchedule = action.payload;
        },
        setDoctorWorkTime(state, action: PayloadAction<IWorkTimeResponse[]>) {
            state.isLoading = false;
            state.isSavingSchedule = false;
            state.error = "";
            state.doctorSchedule.workTime = action.payload.sort(
                (a, b) => a.day_number - b.day_number
            );
        },
        setDoctorVacation(state, action: PayloadAction<IVacationResponse[]>) {
            state.isLoading = false;
            state.isSavingVacation = false;
            state.error = "";
            state.doctorSchedule.vacations = action.payload;
        },
        setDoctorBreakTime(state, action: PayloadAction<IBreakTimeResponse[]>) {
            state.isLoading = false;
            state.isSaveBreak = false;
            state.error = "";
            state.doctorSchedule.additionalBreaks = action.payload;
        },
        setWorkTimes(state, action: PayloadAction<string[]>) {
            state.requestingTimes = false;
            state.workTimes = action.payload;
            state.error = "";
        },
        setSuccessConsultation(state, action: PayloadAction<ISuccessConsultation>) {
            state.newConsultation = action.payload;
            state.error = "";
        }
    }
});

export default consultationSlice.reducer;
