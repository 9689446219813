import type { UserRole } from "./authTypes";

export enum UserStatus {
    APPROVED = "approved",
    ON_REVIEW = "on review",
    NOT_ACTIVE = "not active",
    NEED_UPDATE = "need update"
}

export interface UserResponse {
    firstName: string | null;
    lastName: string | null;
    surName: string | null;
    country: string;
    city: string | null;
    birthdate: string | null;
    languages: Array<string>;
    sex: string | null;
    role: UserRole | "";
    location_city?: null;
    location_country?: null;
    location_timezone?: null;
    isFormActual?: boolean;
    phone: string | null;
    email: string | null;
    activeStatus: UserStatus;
    id: string;
    avatarUrl: string | null;
    originalUrl: string | null;
    publicUrl: string | null;
    refreshToken: string;
    createdAt: string;
    updatedAt: string;
}

export interface IUserState {
    isLoading: boolean;
    error: string;
    user: UserResponse;
    collapsedNavbar: boolean;
}

export interface IUserProfile {
    firstName: string | null;
    lastName: string | null;
    surName: string | null;
    country: string;
    city: string | null;
    birthdate: string | null;
    languages: Array<string>;
    sex: string | null;
    role: UserRole | "";
    phone: string | null;
    email: string | null;
    activeStatus: UserStatus;
    location_city?: null;
    location_country?: null;
    location_timezone?: null;
}
